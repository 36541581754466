import downLoadBtn from '../components/downLoadBtn.vue'
import downSeal from '../components/downSeal.vue'

// 详情
export const detailsConfig = (content) => {
  const span = 8
  return [
    {
      label: '申请企业',
      prop: 'enterpriseName',
      span
    },
    {
      label: '申请类型',
      prop: 'sealAdminType',
      span
    },
    {
      label: '姓名',
      prop: 'personnerName',
      span
    },
    {
      label: '手机号',
      prop: 'mobilePhoneNo',
      span
    },
    {
      label: '邮箱',
      prop: 'email',
      span
    },
    {
      label: '认证状态',
      prop: 'authStatus',
      span
    },
    {
      label: '状态',
      prop: 'enableStatus',
      span
    },
    {
      label: '授权书状态',
      prop: 'authFileStatus',
      isHidden: content.detailInfo.sealAdminType === '个人认证',
      span: 16
    },
    {
      label: '授权书',
      prop: 'authFileId',
      attrs: {
        tipTxt: '下载授权书',
        detailsData: content.detailInfo
      },
      tag: downLoadBtn,
      isHidden: content.detailInfo.sealAdminType === '个人认证',
      span
    },
    {
      label: '印章',
      prop: 'sign',
      attrs: {
        icon: 'iconfont iconxiazai',
        tipTxt: '下载印章',
        keyId: content.detailInfo
      },
      tag: downSeal,
      span,
      isHidden: content.detailInfo.authStatus !== '已认证'
    }

  ]
}
