  // 详情弹出框
<template>
     <base-dialog
      :visible.sync="visibleState"
      :showFooter="false"
      title="查看"
    >
    <formInfo :count="count" :componentList='detailsConfig' :model="detailInfo"  />
      <template slot="footer" >
     <base-button label="关闭" @click="close" type="default"/>
    </template>
    </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import FormInfo from '@/components/packages/form-info/form-info.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { detailsConfig } from '../utils/certificate-detalis-config'
// import { certifyApi } from '@/api/companyApi'
import { getDictLists } from '@/filters/fromDict'

export default {
  props: {
    visible: Boolean,
    name: String,
    keyId: String,
    dropOptions: Array,
    detailsInfoData: Object
  },
  data () {
    return {
      count: 0,
      detailInfo: {}
    }
  },

  components: { BaseDialog, FormInfo, BaseButton },
  computed: {
    detailsConfig () {
      return detailsConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    // 申请类型
    sealAdminOptions () {
      return getDictLists('APPLY_TYPE')
    },
    // 认证状态
    authenticationOptions () {
      return getDictLists('AUTH_STATUS')
    },
    // 授权书状态
    authFileOptions () {
      return getDictLists('SIGN_FILE_STATUS')
    },
    // 禁用状态
    enableOptions () {
      return getDictLists('ENABLE_STATUS')
    }
  },
  watch: {
    visible (val) {
      if (val) {
        // this.detail()
      }
    },
    detailsInfoData (val) {
      if (val) {
        this.detailInfo = this.restructureData(val)
        this.$nextTick(() => {
          this.count++
        })
      }
    }
  },
  methods: {
    // 详情表格数据
    restructureData (val) {
      const data = JSON.parse(JSON.stringify(val))
      // 申请类型
      const [obj] = this.sealAdminOptions.filter(item => item.dictId === data.sealAdminType)
      data.sealAdminType = obj ? obj.dictName : ''
      // 认证状态
      const [obj1] = this.authenticationOptions.filter(item => item.dictId === data.authStatus)
      data.authStatus = obj1 ? obj1.dictName : ''
      // 启用禁用
      const [obj2] = this.enableOptions.filter(item => item.dictId === data.enableStatus)
      data.enableStatus = obj2 ? obj2.dictName : ''
      // 授权书状态
      const [obj3] = this.authFileOptions.filter(item => item.dictId === data.authFileStatus)
      data.authFileStatus = obj3 ? obj3.dictName : ''
      return data
    },
    close () {
      this.$parent.handleFilter()
      this.visibleState = false
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog--center{
  height: 40%;
}
</style>
