// 下载普通文件服务类型
<template>
  <div class="downBtn">
    <base-button
      class="formBtn"
      v-bind="$attrs"
      @click.native="download"
      icon="iconfont iconxiazai"
    />
    <base-button
      class="uploadBtn"
      type="text"
      label=""
      btnType="upload"
      accept=".doc,.docx,.pdf"
      style="margin: 0px 10px"
      action="#"
      :http-request="function (url) {return Upload(url);}"
      icon="iconfont iconshangchuan"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import { certifyApi } from '@/api/companyApi'

export default {
  name: 'downLoadBtn',
  components: { BaseButton },
  props: {},
  computed: {
    file () {
      return this.$attrs
    }
  },
  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    download () {
      console.log(this.$attrs, 'this.$attrs')
      if (this.$attrs.value && this.$attrs.value !== '0') {
        downAPi.download({ keyId: this.$attrs.value }).then((res) => {
          downFile(res)
        })
        // .catch((e) => {
        //   this.error(e)
        // })
      } else {
        console.log(this.$attrs.value, '2')

        this.warning('暂无文件')
      }
    },

    // 重新上传授权书
    async Upload (param) {
      console.log(param, 'param')
      const EmojiReg =
        /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      if (param.file.name.length > 80) {
        return this.warning('文件名称小于80字符')
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const paramRes = await this.UploadFile(param)
      // 调用授权书上传接口
      const subData = { authFileId: paramRes.keyId, sealAdminId: this.$attrs.detailsData.keyId }
      certifyApi.uploadAuthorization(subData)
        .then(res => {
          if (res.data) {
            this.success('更新授权书成功')
            this.$attrs.value = paramRes.keyId
          } else {
            this.warning('更新失败')
          }
        })
    },
    // 调用文件服务上传文件
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    }
  }
}
</script>
<style lang='scss' scoped>
.downBtn {
  display: flex;
  justify-content: flex-start;
}
.formBtn {
  padding: 0;
  text-align: center;
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 50%;
  /deep/.iconfont {
    margin: 0 !important;
  }
}
.uploadBtn{
  width: 30px;
  height: 30px;
  background-color: #2862E7;
  border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
  /deep/  .iconfont{
  margin: 0 !important;
  color: #fff !important;
  font-size: 17px !important;
 }
}

</style>
